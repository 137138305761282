//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { ModalPlugin } from 'bootstrap-vue'
import { Slider } from 'element-ui'

Vue.use(ModalPlugin)
Vue.use(Slider)

export default {
  name: 'Simulateur',
  data () {
    return {
      taux: null,
      montant: null,
      duree: null,
      tauxMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0%')
        },
        3: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '3%')
        }
      },
      montantMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0 €')
        },
        2000000: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '2 000 000 €')
        }
      },
      dureeMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0 mois')
        },
        360: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '360 mois')
        }
      }
    }
  },
  computed: {
    mensualite: function () {
      const total = this.montant + (this.montant * (this.taux / 100))
      const mensualite = ((this.montant > 0) && (this.duree > 0)) ? Math.round(total / this.duree * 100) / 100 : Math.round(total * 100) / 100
      return mensualite
    }
  },
  methods: {
    formatTooltipTaux (value) {
      return value + '%'
    },
    formatTooltipMontant (value) {
      value = parseInt(value)
      if (value < 999) {
        return value + ' €'
      }
      return this.$options.filters.formatMoney(value) + ' €'
    },
    formatTooltipDuree (value) {
      value = parseInt(value)
      return value + ' mois'
    },
    showModal () {
      this.$bvModal.show('simulateur')
    }
  }
}
